import { useEffect, useState } from "react";
import { getDataFromCmsApi } from "../../api/contentful-service";
import { parseHtml } from "../../utils/utils";
import StaticPage from "../../components/static-page";

const RDA = () => {
  const [RDA, setRDA] = useState();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const result = await getDataFromCmsApi("webpage");
    const RDA = result.find((item) => item.pageContentLabel === "Redevelopment_Dissolution_Information");

    setRDA(RDA);
  };

  return RDA && <StaticPage title={RDA.pageTitle}>{parseHtml(RDA.content)}</StaticPage>;
};

export default RDA;
