import { useEffect, useState } from "react";
import StaticPageContainer from "../../components/static-page";
import { getDataFromCmsApi } from "../../api/contentful-service";
import { parseHtml } from "../../utils/utils";


const apiName = "Webpage";
const pageType = "Tax Collector - Supplemental Tax Bills";
const SupplementalTaxBillInfo = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        const result = await getDataFromCmsApi(`${apiName}/${pageType}`);
        setData(result);
    };

    return (
        <StaticPageContainer title={pageType}>
            {data.map((item) => (
                parseHtml(item.content)
            ))}
        </StaticPageContainer>
    )
}

export default SupplementalTaxBillInfo;