import React, { useState } from "react";
import { Button, Dialog, DialogTitle, IconButton, Tooltip } from "@mui/material";
import PropTypes from "prop-types";
import {
  FaFileCode,
  FaFileCsv,
  FaFileExcel,
  FaFileLines,
  FaFilePdf,
  FaFileVideo,
  FaFileWord,
} from "react-icons/fa6";
import { FaExternalLinkSquareAlt } from "react-icons/fa";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import CustomPDFRenderer from "./custom-pdf-renderer";
import "./document-search.scss";

function ViewFileButtons({ hit }) {
  const links = [
    { type: "PDF File", icon: <FaFilePdf />, url: hit.pdf_url },
    { type: "Word File", icon: <FaFileWord />, url: hit.word_url },
    { type: "Excel File", icon: <FaFileExcel />, url: hit.xls_url },
    { type: "CSV File", icon: <FaFileCsv />, url: hit.csv_url },
    { type: "Text File", icon: <FaFileLines />, url: hit.txt_url },
    { type: "JSON File", icon: <FaFileCode />, url: hit.json_url },
    { type: "Video File", icon: <FaFileVideo />, url: hit.video_url },
    { type: "External Link", icon: <FaExternalLinkSquareAlt />, url: hit.external_link },
  ];

  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);

  const PopupDialog = () => {
    if (!selectedValue) return null;

    const fileExtension = selectedValue.url.split(".").pop().toLowerCase();
    return (
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        maxWidth="lg"
        fullWidth
        sx={{
          "& .MuiDialog-paper": {
            height: "80vh",
          },
        }}
      >
        <DialogTitle className="d-flex justify-content-between" sx={{ fontWeight: "bold" }}>
          View {selectedValue.type}
          <Button
            variant="contained"
            onClick={() => {
              window.open(selectedValue.url, "_blank");
            }}
          >
            Download {selectedValue.type}
          </Button>
        </DialogTitle>
        <DocViewer
          pluginRenderers={[CustomPDFRenderer, ...DocViewerRenderers]}
          documents={[{ uri: selectedValue.url, fileType: fileExtension }]}
        />
      </Dialog>
    );
  };

  return (
    <>
      {links.map(
        (link, i) =>
          link.url && (
            <Tooltip
              key={i}
              title={`View ${link.type}`}
              disableInteractive
              slotProps={{
                popper: { modifiers: [{ name: "offset", options: { offset: [0, -14] } }] },
              }}
            >
              <IconButton
                size="small"
                color="primary"
                onClick={() => {
                  if (link.type === "External Link") {
                    window.open(link.url, "_blank");
                  } else {
                    setOpen(true);
                    setSelectedValue(link);
                  }
                }}
              >
                {link.icon}
              </IconButton>
            </Tooltip>
          )
      )}

      <PopupDialog />
    </>
  );
}

ViewFileButtons.propTypes = {
  hit: PropTypes.object,
};

export default ViewFileButtons;
