import { useEffect, useState } from "react";
import { getDataFromCmsApi } from "../api/contentful-service";
import { parseHtml } from "../utils/utils";
import StaticPage from "../components/static-page";

const PrivacyPolicy = () => {
  const [privacyPolicy, setPrivacyPolicy] = useState();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const result = await getDataFromCmsApi("webpage");
    const privacyPolicy = result.find((item) => item.pageContentLabel === "Privacy_Policy");
    setPrivacyPolicy(privacyPolicy);
  };

  return (
    <>
      {privacyPolicy && (
        <StaticPage title={privacyPolicy.pageTitle}>{parseHtml(privacyPolicy.content)}</StaticPage>
      )}
    </>
  );
};

export default PrivacyPolicy;
