import React, { useEffect, useRef } from "react";
import { Box } from "@mui/material";
import { useHits } from "react-instantsearch";
import PropTypes from "prop-types";
import TreeList, { Column, Scrolling } from "devextreme-react/tree-list";
import ViewFileButtons from "./view-file-buttons";

function CustomHits({ autoExpandAll, ...props }) {
  const treeList = useRef(null);
  const { results } = useHits(props);

  useEffect(() => {
    if (!treeList.current) return;
    treeList.current.instance().state(null);
  }, [autoExpandAll]);

  if (!results) return null;
  const { hits } = results;

  // Transform the data into a nested structure with parent-child relationships and unified field
  const transformedData = hits.reduce((acc, hit) => {
    const { objectID, category_path, fiscal_year, division, report_name, ...otherFields } = hit;

    // Extract levels and ensure category_path has valid levels
    const lvl0 = category_path.lvl0 && category_path.lvl0 !== "Unknown Level 0" ? category_path.lvl0 : null;
    const lvl1 = category_path.lvl1 && category_path.lvl1 !== "Unknown Level 1" ? category_path.lvl1.split(" > ").pop() : null;
    const lvl2 = category_path.lvl2 && category_path.lvl2 !== "Unknown Level 2" ? category_path.lvl2.split(" > ").pop() : null;

    // Find or create lvl0 (top-most level)
    let lvl0Node = lvl0 ? acc.find((node) => node.levelName === lvl0) : null;
    if (lvl0 && !lvl0Node) {
      lvl0Node = { id: `lvl0_${lvl0}`, levelName: lvl0, children: [] };
      acc.push(lvl0Node);
    }

    // Find or create lvl1 under this lvl0
    let lvl1Node = lvl1 ? lvl0Node?.children.find((node) => node.levelName === lvl1) : null;
    if (lvl1 && lvl0Node && !lvl1Node) {
      lvl1Node = { id: `lvl1_${lvl1}`, levelName: lvl1, children: [] };
      lvl0Node.children.push(lvl1Node);
    }

    // Find or create lvl2 under this lvl1
    let lvl2Node = lvl2 ? lvl1Node?.children.find((node) => node.levelName === lvl2) : null;
    if (lvl2 && lvl1Node && !lvl2Node) {
      lvl2Node = { id: `lvl2_${lvl2}`, levelName: lvl2, children: [] };
      lvl1Node.children.push(lvl2Node);
    }

    // Reassign reports to the nearest known level (lvl2 > lvl1 > lvl0)
    const parent = lvl2Node || lvl1Node || lvl0Node;
    if (parent) {
      parent.children.push({
        id: objectID,
        parentId: parent.id,
        levelName: report_name, // Use the report name at the document level
        fiscal_year,
        division,
        ...otherFields,
      });
    }

    return acc;
  }, []);

  return (
    <Box sx={{ width: "100%" }}>
      {transformedData.length === 0 ? (
        <div>Loading...</div>
      ) : (
        <TreeList
          id="reports-treelist"
          ref={treeList}
          dataSource={transformedData}
          dataStructure="tree"
          itemsExpr="children"
          showRowLines={true}
          showBorders={true}
          columnAutoWidth={true}
          autoExpandAll={autoExpandAll}
          onRowPrepared={(e) => {
            if (e.rowType === "data") {
              // Check if the row has children or not
              const isLeafNode = e.node.children.length > 0;

              // Apply background color if it's the last child
              if (isLeafNode) {
                e.rowElement.style.backgroundColor = "#efefef";
              }
            }
          }}
        >
          <Scrolling mode="standard" />
          {/* Unified common name column for levels */}
          <Column
            dataField="levelName"
            caption="Report"
            cellRender={(cellData) => {
              const hasChildren = cellData.row.node.children.length > 0;
              return (
                <div
                  style={{
                    fontWeight: hasChildren ? "bold" : "normal",
                  }}
                >
                  {cellData.value}
                </div>
              );
            }}
          />
          <Column
            caption="View Report"
            cellRender={(cellData) => <ViewFileButtons hit={cellData.data} />}
            alignment="center"
          />
          {/* Other relevant columns at the document level */}
          <Column dataField="fiscal_year" caption="Fiscal Year" alignment="center" />
          <Column dataField="quarter" caption="Quarter" alignment="center" />
          <Column dataField="month" caption="Month" alignment="center" />
          <Column dataField="division" caption="Division" alignment="center" />
        </TreeList>
      )}
    </Box>
  );
}

CustomHits.propTypes = {
  autoExpandAll: PropTypes.bool,
};

export default CustomHits;
