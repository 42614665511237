import React, { useEffect, useRef, useState } from "react";
import { Card, CardContent } from "@mui/material";
import { getDataFromCmsApi } from "../../api/contentful-service";
import { getRandomColors, parseHtml } from "../../utils/utils";
import Loader from "../loader";
import PropTypes from "prop-types";
import "./division-services.scss";

const apiName = "DivisionService";

function DivisionServices({ divisionAbbreviation, sectionTitle }) {
  const [serviceTiles, setServicesTiles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const divRefs = useRef([]);

  useEffect(() => {
    const maxHeight = Math.max(...divRefs.current.map((ref) => ref.getBoundingClientRect().height));
    divRefs.current.forEach((ref) => {
      ref.style.height = `${maxHeight}px`;
    });
  }, [serviceTiles]);

  useEffect(() => {
    getData().then(() => setIsLoading(false));
  }, []);

  const getData = async () => {
    const result = (await getDataFromCmsApi(`${apiName}/${divisionAbbreviation}`)).sort(
      (a, b) => a.sortOrder - b.sortOrder
    );
    setServicesTiles(result);
  };

  const serviceTilesColors = getRandomColors(serviceTiles?.length || 0, divisionAbbreviation);

  return isLoading ? (
    <Loader />
  ) : (
    <div className="container d-flex flex-column justify-content-center px-3 px-lg-5 py-5">
      <h1 className="display-4 text-center py-3">{sectionTitle}</h1>
      <div className="row justify-content-center">
        {serviceTiles.map((item, i) => (
          <div key={i} className="col-12 col-sm-6 col-lg-4 col-xl-3 p-3 my-2">
            <Card
              className="h-100"
              sx={{
                borderRadius: 2,
              }}
            >
              <CardContent
                ref={(el) => (divRefs.current[i] = el)}
                className="division-hover-bubble-container d-flex flex-column justify-content-start px-3 py-2 text-center text-white"
                disabled={true}
                sx={{ backgroundColor: serviceTilesColors[i] }}
              >
                <div className="division-hover-bubble" />
                <div className="service-content">
                  {item.iconUrl && (
                    <div className="p-3">
                      <img height="57px" src={`https:${item.iconUrl}`} alt={item.title} />
                    </div>
                  )}
                  <h3 className="py-2 fw-bold">{item.serviceLabel}</h3>
                  {parseHtml(item.serviceDescription)}
                </div>
              </CardContent>
            </Card>
          </div>
        ))}
      </div>
    </div>
  );
}

DivisionServices.propTypes = {
  divisionAbbreviation: PropTypes.string.isRequired,
  sectionTitle: PropTypes.string,
};

export default DivisionServices;
