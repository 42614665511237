import React, { useEffect, useState } from "react";
import Bio from "../../components/bio/bio";
import StaticPageContainer from "../../components/static-page";
import { getDataFromCmsApi } from "../../api/contentful-service";
import { parseHtml } from "../../utils/utils";

const apiName = "Webpage";
const pageType = "About ATC";

const About = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const result = await getDataFromCmsApi(`${apiName}/${pageType}`);
    setData(result);
  };

  return (
    <div className="container">
      <StaticPageContainer title={data[0] && parseHtml(data[0].pageTitle)} width="large">
        {data.map((item, i) => (
          <Bio
            key={i}
            imgPositionLeft={i % 2 === 0 ? true : false}
            imgSrc={item.contentImageUrl}
            title={item.pageContentLabel}
            description={parseHtml(item.content)}
          />
        ))}
      </StaticPageContainer>

      <div className="pb-5 my-5">
        <h3 className="text-center display-6 mb-5"> ATC History: A Look Back In Time 1853 - Present </h3>
        <iframe
          title="atc history"
          src="https://cdn.knightlab.com/libs/timeline3/latest/embed/index.html?source=1BJ3JbgXLnt2UNCyMWp4QCiD7K0y2VJlh39TThbhLmSU&font=Default&lang=en&start_at_end=true&initial_zoom=2&height=650"
          width="100%"
          height="650"
          allowFullScreen
          frameBorder="0"
        ></iframe>
      </div>
    </div>
  );
};

export default About;
