import React, { useEffect, useState } from "react";
import { getDataFromCmsApi } from "../../api/contentful-service";
import { parseHtml } from "../../utils/utils";
import InformationalTiles from "../../components/treasurer/informational-tiles";
import StaticPageContainer from "../../components/static-page";

const apiName = "FeaturedItem";
const featuredItemType = "Awards and Accolades";

function AwardsAndAccolades() {
  const [awards, setAwards] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const result = await getDataFromCmsApi(`${apiName}/${featuredItemType}`);
    setAwards(result);
  };

  return (
    <StaticPageContainer title={featuredItemType}>
      {awards.map((award, index) => (
        <div key={index}>
          <InformationalTiles
            icon={award.iconImage}
            title={award.title}
            content={parseHtml(award.description)}
            secondaryIcon={award.secondaryIconImage}
            secondaryContent={award.secondaryDescription}
            side={"right"}
          />
        </div>
      ))}
    </StaticPageContainer>
  );
}
export default AwardsAndAccolades;
