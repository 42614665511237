import AlertService from "../../components/alert-service/alert-service";
import DivisionServices from "../../components/division-homepage/division-services";
import DivisionAboutBanner from "../../components/division-homepage/division-about-banner";
import DivisionContact from "../../components/division-homepage/division-contact";
import PropTypes from "prop-types";

function SAPHome({ division }) {
    return (
        <>
            <div className="container">
                <AlertService divisionCode={division.divisionAbbreviation} />
            </div>
            <div>
                <DivisionServices divisionAbbreviation={division.divisionAbbreviation} sectionTitle="Our Services" />
                <DivisionAboutBanner
                    divisionAbbreviation={division.divisionAbbreviation}
                    divisionTag={division.tag}
                    linkTo="/sap/about"
                />
                <DivisionContact
                    divisionAbbreviation={division.divisionAbbreviation}
                    sectionTitle={"Contact SAP Center of Excellence"}
                />
            </div>
        </>
    );
}

SAPHome.propTypes = {
    division: PropTypes.shape({
        divisionAbbreviation: PropTypes.string.isRequired,
        tag: PropTypes.string.isRequired,
    }).isRequired,

};

export default SAPHome;
