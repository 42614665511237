import React, { } from "react";
import { parseHtml } from "../../utils/utils";
import PropTypes from "prop-types";
import { Card } from "@mui/material";
import { AccessTime, Business, Call } from "@mui/icons-material";

const DivisionGroupContact = ({ divisionContact }) => {

    return (
        <Card className="w-100">
            <h3 className="pb-4 text-center" style={{ margin: "auto", padding: "10px", height: "120px", color: divisionContact.foregroundColor, backgroundColor: divisionContact.backgroundColor }}>{divisionContact.divisionGroupLabel}</h3>
            <hr />
            <div className="pb-3 d-flex">
                <Business className="me-3" />
                <div className="w-100">
                    <h5>Address:</h5>
                    <p>
                        {divisionContact.streetAddress} <br />
                        {`${divisionContact.city}, ${divisionContact.state} ${divisionContact.zipCode}`} <br />
                    </p>
                </div>
            </div>
            <div className="pb-3 d-flex">
                <AccessTime className="me-3" />
                <div className="w-100">{parseHtml(divisionContact.operatingHours)}</div>
            </div>
            <div className="pb-3 d-flex">
                <Call className="me-3" />
                <div className="w-100">
                    <h5>Phone:</h5>
                    <p>
                        {divisionContact.phone1} <br />
                        {divisionContact.phone2} <br />
                        {divisionContact.phone3}<br />
                        {divisionContact.phone4} <br />
                        {divisionContact.phone5}
                    </p>
                </div>
            </div>

        </Card>

    );
};

DivisionGroupContact.propTypes = {
    divisionContact: PropTypes.object,
};

export default DivisionGroupContact;