import React, { useEffect, useState } from "react";
import algoliasearch from "algoliasearch/lite";
import { InstantSearch, SearchBox, useHits, RefinementList, useSearchBox } from "react-instantsearch";
import StaticPageContainer from "../../components/static-page";
import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, Typography } from "@mui/material";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";
import { FilterList } from "@mui/icons-material";
import { parseHtml } from "../../utils/utils";
import CustomFilterNumber from "../../components/filter/filter-number";
import CustomClearFilterButton from "../../components/filter/clear-filter";
import CustomFilterWidget from "../../components/filter/filter-widget";
import { singleIndex } from "instantsearch.js/es/lib/stateMappings";
import PropTypes from "prop-types";
import CopyHashLinkButton from "../../components/copy-hash-link-button";
import "./faq.scss";

const appId = process.env.REACT_APP_ALGOLIA_APP_ID;
const apiKey = process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY;
const searchClient = algoliasearch(appId, apiKey);

const primaryTextColor = "#5a5e9a";
const primaryBgColor = "#777aaf";
const backgroundColorLight = "#fbfbfb";
const backgroundColorDark = "#6c6fa8";
const filterbackgroundColor = "#f6f7fe";

const CustomHighlight = ({ attribute, hit }) => {
  const highlightValue = hit._highlightResult[attribute].value;
  const parsedHTML = parseHtml(highlightValue);

  return <>{parsedHTML}</>;
};

CustomHighlight.propTypes = {
  attribute: PropTypes.string,
  hit: PropTypes.object,
};

const CustomAccordion = styled(Accordion)(() => {
  return {
    boxShadow: "none",
    border: "none",
    "&::before": {
      display: "none",
    },
  };
});

const CustomHits = ({ setHits, setIsQueryEmpty, ...props }) => {
  const { results } = useHits(props);
  const { query } = useSearchBox();

  if (!results) return null;
  const { hits } = results;
  const isQueryEmpty = !query || query.trim() === "";

  setHits(hits);
  setIsQueryEmpty(isQueryEmpty);

  if (!hits.length) {
    return <Typography variant="h6">No results found</Typography>;
  }

  const groupedByFaqType = hits.reduce((acc, { typeName, typeDescription, ...rest }) => {
    if (!acc[typeName]) {
      acc[typeName] = {
        description: typeDescription,
        items: [],
      };
    }
    acc[typeName].items.push({ typeName, typeDescription, ...rest });
    return acc;
  }, {});

  return (
    <div>
      {Object.keys(groupedByFaqType).map((type) => {
        const formattedId = type.trim().replace(/\s+/g, "-");

        return (
          <div id={formattedId} key={type} className="faq-text pb-5 mb-3">
            <div className="d-flex align-items-center" style={{ columnGap: "0.7rem" }}>
              <h4 style={{ color: primaryTextColor }}>{type}</h4>
              <CopyHashLinkButton elementId={formattedId} iconSize={18} />
            </div>
            <div className="fs-6">{parseHtml(groupedByFaqType[type].description)}</div>

            {groupedByFaqType[type].items.map((hit, i) => (
              <div id={hit.objectID} key={i} className="py-1">
                <CustomAccordion
                  defaultExpanded={hit.objectID === window.location.hash.replace("#", "")}
                  style={{ backgroundColor: backgroundColorLight, borderRadius: 10 }}
                  onChange={(e, expanded) => {
                    if (expanded) {
                      window.history.replaceState(null, "", `#${hit.objectID}`);
                    }
                  }}
                >
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    <Typography
                      variant="h7"
                      gutterBottom
                      className="d-flex m-0"
                      style={{ color: primaryTextColor, columnGap: "0.45rem" }}
                    >
                      <CustomHighlight attribute="question" hit={hit} />
                      {/* <CopyHashLinkButton elementId={hit.objectID} iconSize={15} /> */}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails className="d-flex pt-0">
                    <Typography variant="body1" gutterBottom>
                      <CustomHighlight attribute="answer" hit={hit} />
                    </Typography>
                  </AccordionDetails>
                </CustomAccordion>
              </div>
            ))}
          </div>
        );
      })}
    </div>
  );
};

CustomHits.propTypes = {
  setHits: PropTypes.func,
  setIsQueryEmpty: PropTypes.func,
};

const filterColumns = [
  {
    filterColumnName: "Filter By Topic",
    refinementListComponent: <RefinementList attribute="typeName" className="long-filter-list" />,
  },
  {
    filterColumnName: "Filter By Division",
    refinementListComponent: <RefinementList attribute="divisionNames" />,
  },
];

function FAQ(props) {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [hits, setHits] = useState([]);
  const [isQueryEmpty, setIsQueryEmpty] = useState(true);

  useEffect(() => {
    if (!isQueryEmpty) {
      return;
    }

    // Initial scroll on page load
    if (window.location.hash && hits.length > 0) {
      scrollToQuestion();
    }

    // Listen to hash changes
    window.addEventListener("hashchange", scrollToQuestion);
    return () => {
      window.removeEventListener("hashchange", scrollToQuestion);
    };
  }, [hits, isQueryEmpty]);

  const scrollToQuestion = () => {
    const elementId = window.location.hash.replace("#", "");
    const navbarHeight = document.querySelector("header")?.offsetHeight || 0;

    // Find the element by ID and scroll to it
    const targetElement = document.getElementById(elementId);
    if (targetElement) {
      window.scrollTo({
        top: targetElement.offsetTop - navbarHeight - 20,
        behavior: "smooth",
      });
    }
  };

  return (
    <StaticPageContainer title={"Frequently Asked Questions"}>
      <InstantSearch
        searchClient={searchClient}
        indexName={process.env.REACT_APP_ALGOLIA_FAQ_INDEX}
        routing={{
          stateMapping: singleIndex(`${process.env.REACT_APP_ALGOLIA_FAQ_INDEX}`),
        }}
      >
        {/* Search */}
        <div className="d-flex justify-content-center py-2">
          <SearchBox placeholder="Search FAQ" searchAsYouType className="col-12 col-lg-6" />
        </div>

        {/* Filter */}
        <div className="d-flex justify-content-center justify-content-sm-start column-gap-5 pt-2">
          <Button
            variant="text"
            startIcon={<FilterList />}
            endIcon={
              <CustomFilterNumber isFilterOpen={isFilterOpen} textColor={backgroundColorDark} {...props} />
            }
            onClick={() => {
              setIsFilterOpen(!isFilterOpen);
            }}
            sx={{
              px: 1.5,
              color: isFilterOpen ? "white" : primaryTextColor,
              backgroundColor: isFilterOpen ? primaryBgColor : "white",
              "&:hover": {
                backgroundColor: isFilterOpen ? backgroundColorDark : backgroundColorLight,
              },
            }}
          >
            Filter
          </Button>
          <CustomClearFilterButton textColor={primaryTextColor} backgroundColor={backgroundColorLight} />
        </div>

        <CustomFilterWidget
          filterColumns={filterColumns}
          isFilterOpen={isFilterOpen}
          backgroundColor={filterbackgroundColor}
          {...props}
        />

        {/* Results */}
        <Grid container my={2} py={5}>
          <CustomHits setHits={setHits} setIsQueryEmpty={setIsQueryEmpty} {...props} />
        </Grid>
      </InstantSearch>
    </StaticPageContainer>
  );
}

FAQ.propTypes = {
  resultsState: PropTypes.object,
};

export default FAQ;
