import { useEffect, useState } from "react";
import { getDataFromCmsApi } from "../../api/contentful-service";
import { parseHtml } from "../../utils/utils";
import StaticPage from "../../components/static-page";
import { Button, Checkbox, FormControlLabel } from "@mui/material";
import { Link } from "react-router-dom";
import { govHubSubPath } from "../../utils/utils";

const BulkTaxPayments = () => {
  const [bulkTaxPayments, setBulkTaxPayments] = useState();
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const result = await getDataFromCmsApi("webpage");
    const bulkTaxPayments = result.find((item) => item.pageContentLabel === "Bulk_Tax_Payments");

    setBulkTaxPayments(bulkTaxPayments);
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  return (
    bulkTaxPayments && (
      <StaticPage title={bulkTaxPayments.pageTitle}>
        {parseHtml(bulkTaxPayments.content)}
        <FormControlLabel
          control={
            <Checkbox
              checked={checked}
              onChange={handleChange}
              inputProps={{ "aria-label": "controlled" }}
              sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
            />
          }
          label="I have Read and Accept the Terms and Conditions described above."
          sx={{ "& .MuiTypography-root": { fontWeight: "bold", fontSize: "large" } }}
        />
        <div className="text-center p-3">
          <Button
            component={Link}
            to={`/${govHubSubPath}/property-tax/upload`}
            variant="contained"
            disabled={!checked}
            sx={{ px: 3 }}
          >
            Continue
          </Button>
        </div>
      </StaticPage>
    )
  );
};

export default BulkTaxPayments;
