import { useEffect, useState } from "react";
import { getDataFromCmsApi } from "../../api/contentful-service";
import { parseHtml } from "../../utils/utils";
import StaticPage from "../../components/static-page";

const AchWirePayment = () => {
  const [achWirePayment, setAchWirePayment] = useState();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const result = await getDataFromCmsApi("webpage");
    const achWirePayment = result.find(
      (item) => item.pageContentLabel === "ACH_Wire_Payment_Instructions"
    );

    setAchWirePayment(achWirePayment);
  };

  return (
    achWirePayment && (
      <StaticPage title={achWirePayment.pageTitle}>
        {parseHtml(achWirePayment.content)}
      </StaticPage>
    )
  );
};

export default AchWirePayment;
